export  const contactService = {
    
    mailContact,
    mailAppointment
 };

 //mail contact
function mailContact(object) {
    const requestOptions = {
       method: 'POST',
       headers: {
          'Content-type': 'application/json',
      },
       body: object
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/contact/mail`,requestOptions).then(handleResponse);
 } 
 function mailAppointment(object) {
   const requestOptions = {
      method: 'POST',
      headers: {
         'Content-type': 'application/json',
     },
      body: object
   };
   return fetch(`${process.env.REACT_APP_BASE_URL}/contact/mailAppointment`,requestOptions).then(handleResponse);
} 
 







 function handleResponse(response) {
    return response.text().then(text => {
       const data = text && JSON.parse(text);
       if (!response.ok) {
          const error = (data && data.message) || response.statusText;
          return Promise.reject(error);
       }
       return data;
    });
 }
import React, { Component } from 'react';
import {Link} from 'react-router-dom'
class TextMainFooter extends Component {
    constructor(props) {
        super(props);
        this.state = {
          
            
            name: window.location.href.split('/')[3],
           
            
        }
    }
    render() {
        return (
            <div className="widget widget-text">
              {this.state.name==="services" || this.state.name==="blog-single" || this.state.name==="service"  ? 
                                    <Link to="#" title="">
                                        <img src="../images/logo-blog.webp" alt=" glocal it solutions " width={260} height={130} title=" glocal it solutions "  />
                                    </Link>
                                    :
                                    
                                    <Link to="#" title="">
                                        <img src="../images/logo-blog.webp" alt=" glocal it solutions " width={260} height={130} title=" glocal it solutions "  />

                                    </Link>
                                    }
                                
               	
            </div>
            /* <!-- /.widget-text --> */
        );
    }
}

export default TextMainFooter;
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Header, TopBar, Footer, Loader } from '../../layouts/general';
import { cmsService } from '../../../_services/cms.service';
import { Helmet } from 'react-helmet';
class Impressum extends Component {
    constructor(props) {
        super(props);
        this.state = {
            headers: [
                {
                    id: 1,
                    names: 'Pages'
                }
            ],
            titleServices: [
                {
                    id: 1,
                    title: 'IMPRESSUM',
                }
            ],
            data: [],
            meta: []
        }
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        this.getImpressumPage()
    }
    getImpressumPage() {
        cmsService.getRechtlichesById(1).then(res => {
            this.setState({ data: res })
            if (res.Meta) {

                this.setState({
                    meta: res.Meta
                })

            }
        })
    }
    createMarkup(value) {
        return { __html: value };
    }
    render() {
        const { data, meta } = this.state
        return (
            <div className="bg-body3">
                <div className="boxed">
                    <Loader />
                    <Helmet>
                        <title>{meta?.metaTitle}</title>
                        <meta name="description" content={meta?.metaDesc} />
                        <meta name="robots" content="index ,follow" />
                    </Helmet>
                    <TopBar />
                    {
                        this.state.headers.map(data => (
                            <Header data={data} key={data.id} />
                        ))
                    }
                    {data ?
                        <>
                            <div className="page-title pages-margintop">
                                <div className="container">
                                    <div className="row">
                                        {data.title ?
                                            <div className="col-md-12">
                                                <div className="page-title-heading">
                                                    <h1 className="h1-title">{data.title}</h1>
                                                </div>

                                                <div className="clearfix"></div>
                                            </div>
                                            : null}
                                    </div>
                                </div>
                            </div>
                            {data.title ?
                                <section className="flat-row pd-services-post">
                                    <div className="container">
                                        <div className="row">
                                            {data.title ?
                                                <div className="col-md-12">

                                                    <p className="sub-title-section apage" dangerouslySetInnerHTML={this.createMarkup(data.content)} />

                                                </div>
                                                : null}
                                        </div>
                                    </div>
                                </section>
                                : null}
                            {data.content ?
                                <Footer />
                                : null}
                        </>
                        : null
                    }
                </div>
            </div>
        );
    }
}

export default Impressum;
export const dateFormatService = {
    formatDate,
    formatDateHour,
    compareDate

};


// format date and hours
function formatDateHour(e) {
    if(e)
    {let month = new Date(e).getMonth() + 1
        if (month < 10) {
            month = '0' + month;
       }
    let day = new Date(e).getDate()
    if (day < 10) {
         day = '0' + day;
    }
    let date = day+ '.' + month + '.' + new Date(e).getFullYear()
        + ' ' + new Date(e).getHours() + ':' + new Date(e).getMinutes()


    return (date)}
}

// format date 
function formatDate(e) {
    if(e)
  {  let month = new Date(e).getMonth() + 1
    if (month < 10) {
        month = '0' + month;
   }
    let day = new Date(e).getDate()
    if (day < 10) {
         day = '0' + day;
    }
    let date = day + '.' + month + '.' + new Date(e).getFullYear()


    return (date)}
}
//compare date
function compareDate(e) {
if(e)
   { let month = new Date(e).getMonth() + 1
    if (month < 10) {
        month = '0' + month;
   }
    let day = new Date(e).getDate()
    if (day < 10) {
         day = '0' + day;
    }
    let date = new Date(e).getFullYear() + '' + month + '' + day
    return (date)}
}



import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import moment from "moment";
import Hyphenated from 'react-hyphen';
import de from 'hyphenated-de';
class newBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
  
    
    render() {
        return (
         
            this.props.datanewBox.length>0 ?
            <div className="col-md-4">
                <div className="slidebar-news">
                    <aside className="widget widget-recent-news">
                        <ul className="recent-news">
                                {
                                    this.props.datanewBox.slice(0,4).map(data => (
                                        <li key={data.id} >                                    
                                            <div className="thumb">
                                                <span className="overlay-pop"></span>
                                                <Link to={`/blog-single/${data?.title.replaceAll(" ","_")}`} onClick={() => {window.location.href=`/blog-single/${data?.title.replaceAll(" ","_")}`}} >
                                                    <img src={`${process.env.REACT_APP_CMS_URL}` +data?.image?.formats?.thumbnail?.url} alt="blog1" style={{height:"73px",width:"100px"}} />
                                                </Link>
                                                {/* <!-- /.thumb -->  */}
                                            </div>
                                            {/* <!-- /.row --> */}
                                            <Hyphenated language={de}>
                                            <div className="text">
                                                <h4>
                                                    <Link to={`/blog-single/${data?.title.replaceAll(" ","_")}`} onClick={() => {window.location.href=`/blog-single/${data?.title.replaceAll(" ","_")}`}} title="">{data.title}</Link>
                                                </h4>
                                                <div className="entry-post">
                                                    <p>{moment(data?.updated_at).format('DD.MM.YYYY')}</p>
                                                </div>
                                            </div>
                                            </Hyphenated>
                                            {/* <!-- /.content-post -->*/}
                                        </li>
                                    ))
                                    
                                }
                        </ul>
                    </aside>
                    {/* <!-- /.widget-recent-news --> */}
                </div>
                {/* <!-- /.slidebar-news --> */}
            </div>
            :null
        );
    }
}

export default newBox;
import Home from './Home'
import { ServiceOverview } from '../layouts/general/services'
import Contact from './Contact'
import BlogSingle from './BlogSingle'
import BlogGrid from './BlogGrid'
import Impressum  from './politic/Impressum'
import Daten from './politic/daten'
import AGB from './politic/agb'
import NotFound from "./404/404PAGE";



const routes = [
    { path: '/', component: Home},
    { path: '/service/:name', component: ServiceOverview},
    { path: '/contact-v1', component: Contact},
    { path: '/blog-single/:name', component: BlogSingle},
    { path: '/blog-grid', component: BlogGrid},
    {path:'/impressum', component:Impressum},
    {path:'/datenschutzerklarung', component:Daten},
    {path:'/agb', component:AGB},
    {path:'*', component:NotFound}

    
]

export default routes;
import React from 'react';
import { Helmet } from 'react-helmet';
class Meta extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  }

   


  render() {
    return (
      this.props.metaData ?
      <Helmet
      title={this.props.metaData?.Meta?.metaTitle}
  meta={[
      {"name": "description", "content":this.props.metaData?.Meta?.metaDesc},
      {"name": "robots", "content":"index ,follow"}
  ]}
      
      
      />
      :null
    )
  }
}
export default Meta;
import React, { Component } from 'react';
import swal from 'sweetalert';
import { contactService } from '../../../../_services/contact.service';
import {
    EReCaptchaV2Size,
    EReCaptchaV2Theme,
    ReCaptchaProvider,
    ReCaptchaV2,
} from 'react-recaptcha-x';

class FormApply extends Component {
    constructor(props) {
        super(props);
        this.state = {
            telefon: "",
            verified: false,
            service_name:'',
            errors: {},
            valid: true,
        
        }
        }
    v2Callback = (token) => {
        this.setState({
            verified: true
        });
        if (typeof token === 'string') {
        } else if (typeof token === 'boolean' && !token) {
        } else if (token instanceof Error) {
        }
    };
     // on change value of input
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  }
  handleServiceNameChange = event => {
    this.setState({service_name: event.target.value});
}
  validate() {
    let isValid = true
    let errors = {};

   
    if (!this.state.telefon) {
      errors["telefon"] = "Bitte gib deine Telefonnummer ein.";
      isValid = false
    } else if (!/[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/.test(this.state.telefon)) {
      errors["telefon"] = "Telefonnummer ist ungültig";
      isValid = false
    }

   

    this.setState({
      errors: errors
    });
    return isValid
  }

  mailContact() {
    let object = JSON.stringify({
      service_name: this.state.service_name,
     telefon: this.state.telefon,
      subject:'Glocal - Rückruf Dringend'
     

    })

    contactService.mailContact(object).then(results => {
      swal({
        title: "Kontaktanfrage",
        text: "Vielen Dank für Ihre Anfrage. Wir melden uns Zeitnahe bei Ihnen!",
         dangerMode: true
      }).then((willDelete) => {
        if (willDelete) {
          window.location.href = "/"
          document.getElementById('telefon').value = ''


        }
      });

    }).catch(err => {

      console.error("error contact mail", err)

    });
  }
  // on submit for update
  onSubmit = event => {

    event.preventDefault();
    const isValid = this.validate()
    
    if (isValid) {
      this.mailContact();
    }
  }
    render() {
        return (
            <div className="flat-callback-form">
                <form id="contactform" method="post" action="./contact/contact-process2.php" noValidate="novalidate" >
                    <div className="flat-field">
                        <div className="field-one-half centered">
                            <label>Wir rufen Sie zurück! *</label>
                            <select name="discuss" onChange={this.handleServiceNameChange} value={this.state.service_name}>
                            <option value="" disabled hidden>Bitte Betreff auswählen</option>
                            <option value="Individuelle SaaS-Lösung" > Individuelle SaaS-Lösung</option>
                            <option value="DATA Management">DATA Management</option>
                            <option value="App Entwicklung">App Entwicklung</option>
                            <option value="Anderes Thema">Anderes Thema</option>
                            </select>
                        </div>
                        {/* <!-- /.field-one-half --> */}
                        <div className="field-one-half field-email ">
                            <input type="text" id="telefon"  name="telefon" placeholder="Ihre Rückrufnummer" required="required"
                            value={this.state.telefon}
                            onChange={this.onChange} />
                            <div className="text-danger textmargin">{this.state.errors.telefon}</div>
                             
                        </div>
                        {/* <!-- /.field-one-half --> */}
                        <div className="clearfix"></div>
                    </div>
                    <div className="flat-field centered">
                        <div className="field-one-half ">
                        <ReCaptchaProvider
                                                        siteKeyV2="6LcLgN4cAAAAAPXZ3a23Ae9gtqqIJaQSgJL7Kauc"
                                                        langCode="de"

                                                    >
                                                        <ReCaptchaV2
                                                        className="paddingrecap"
                                                            callback={this.v2Callback}
                                                            theme={EReCaptchaV2Theme.Light}
                                                            size={EReCaptchaV2Size.Normal}
                                                            id="callback"
                                                            data-test-id="callback"
                                                            tabindex={0}
                                                        />
                                                    </ReCaptchaProvider>
                                                </div>
                        <div className="field-one-half field-submit centered">
                            <button type="submit" name="submit" onClick={e => this.onSubmit(e)} disabled={!this.state.verified} className="button-submit-field marginbtn ">JETZT SENDEN</button>
                        </div>
                    </div>
                    {/* <!-- /.flat-field --> */}
                </form>
                {/* <!-- /.form --> */}
            </div>
            /* <!-- /.callback-form --> */
        );
    }
}

export default FormApply;
import React, { Component } from 'react';
import BottomBar from './BottomBar';
import { UsefulLink, RecentImage, TopFooter,TextMainFooter } from './footers/index';
import { Link } from "react-router-dom";
import { CookieBanner } from "@palmabit/react-cookie-law";

class Footer extends Component {
    AcceptGoogleAnalyticsCookies = () => { }
    AcceptGoogleAdsCookies() {
    }
    AcceptAllCookies() {
    }

    render() {
        return (
            <div>
                 <CookieBanner
                    message="Zur Verbesserung der Nutzerqualität und für interne Auswertungen nutzt diese Seite Cookies"
                    policyLink="/datenschutzerklarung"
                    privacyPolicyLinkText="Datenschutzerklärung"
                    styles={{
                        dialog: {
                            backgroundColor: "#333", position: "fixed", top: "0px", left: "0px", right: "0px", zIndex: "100000",
                            padding: "40px 20px"
                        },
                        message: { minHeight: "32px", padding: "10px 0px", color: "#fff" },
                        container: {
                            maxWidth: "1300px", marginLeft: "auto",
                            marginRight: "auto", overflow: "hidden"
                        },
                        optionLabel: {
                            height: "auto", width: "auto", minHeight: "14px", fontSize: "12pt", color: "rgb(198, 198, 198)", display: "inline-block", padding: "1px 0px 0px 10px", position: "relative",
                            top: "0px", left: "0px", zIndex: "1", cursor: "default", verticalAlign: "top"
                        },
                        policy: { color: "#de472c" },
                        checkbox: { width: "20px", height: "20px", margin: "5px" },
                        button: {
                            backgroundColor: "#de472c", color: "#fff", fontSize: "15px", borderRadius: "3px", cursor: "pointer", margin:'0px 2px',
                            transition: "all 0.4s"
                        }
                    }}
                    wholeDomain={true}
                    necessaryOptionText="MOLLIE,YOUTUBE VIDEO"
                    marketingOptionText="GOOGLE ADS CONVERSION TRACKING"
                    preferencesOptionText="GOOGLE ANALYTICS"
                    showStatisticsOption={false}
                    acceptButtonText="Alle akzeptieren"
                    declineButtonText="Ablehnen"
                    savePreferencesButtonText="Einstellungen speichern"
                    managePreferencesButtonText="Meine Cookies verwalten"
                    showDeclineButton
                    preferencesDefaultChecked
                    marketingDefaultChecked
                    //onAccept={() => {}}
                    onAcceptPreferences={() => { this.AcceptGoogleAnalyticsCookies() }}
                    onAcceptMarketing={() => { this.AcceptGoogleAdsCookies() }}
                />
            <footer id="footer">
                <div className="footer-widgets">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <TopFooter />
                            </div>
                            {/* <!-- /.col-md-12 --> */}
                        </div>
                        {/* <!-- /.row --> */}
                        <div className="row widget-box">
                            <div className="col-md-4">
                                <TextMainFooter />
                            </div>
                            {/* <!-- /.col-md-4 --> */}
                            <div className="col-md-4 footer-mobile">
                                <UsefulLink />
                            </div>
                            {/* <!-- /.col-md-4 --> */}
                            <div className="col-md-4 footer-mobile">
                                <RecentImage />
                            </div>
                            {/* <!-- /.col-md-4 --> */}
                        </div>
                        {/* <!-- /.row .widget-box --> */}
                    </div>
                    {/* <!-- /.container --> */}
                </div>
                {/* <!-- /.footer-widgets --> */}
                <BottomBar />
                {/* footer-bottom  */}
            </footer>
            <div className="button-go-top">
                <Link to="#" title="" className="go-top">
                    <i className="fa fa-chevron-up"></i>
                </Link>
		    </div>
            </div>
            // <!-- /#footer -->
        );
    }
}

export default Footer;
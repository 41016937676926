import React, { Component } from 'react';
import SkillBox1 from './SkillBox1'
import SkillBox2 from './SkillBox2'

import Hyphenated from 'react-hyphen';
import de from 'hyphenated-de';
class Skills extends Component {
	constructor(props) {
        super(props);
        this.state = {
          
           
        }
    }
        componentDidMount(){
        }
      
          createMarkup(value) {
            return { __html: value }
        }
    render() {
        return (
           this.props.skilldata.Section4?
            <section className="flat-row flat-iconbox bg-theme">
				<div className="container">

					<div className="row" >
								<div className="col-md-12" key={this.props.skilldata.Section4?.id}>
									<div className="title-section left">
									<h2>{this.props.skilldata.Section4?.title1} <span style={{color:"#de472c"}}>{this.props.skilldata.Section4?.title2}</span> {this.props.skilldata.Section4?.title3}</h2>
                                       </div>
                                <Hyphenated language={de}>

									   <p   dangerouslySetInnerHTML={this.createMarkup(this.props.skilldata.Section4?.description)} className="service-p textAlign" />
									</Hyphenated>
									{/* <!-- /.title-section --> */}

								</div>
						
					</div>
					{/* <!-- /.row --> */}
					<SkillBox1 skills={this.props.skilldata.Section4} />
                
					<div className="row">
						<div className="col-md-12">
							<div className="height80"></div>
						</div>
					</div>
					<SkillBox2 skills={this.props.skilldata.Section4} />
                
                    

				</div>
        	</section>
            :null
        // <!-- /.flat-row-iconbox -->

     
           
        );
    }
}

export default Skills;
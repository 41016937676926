import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import moment from "moment";
import { cmsService } from '../../../_services/cms.service';
import { dateFormatService } from '../../../_services/dateFormat.service';
class SidebarBlog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listrecentpost: [],
           
           
            category: [
                {
                    id: '1',
                    title: 'Technologien'
                },
                {
                    id: '2',
                    title: 'Marketing'
                },
                {
                    id: '3',
                    title: 'News'
                },
            ],
        }
        this.getblogpage()
    }
    getblogpage(){
        cmsService.getBlogs().then(res=>{
       
           	const sortedArray = res.sort((a, b) => dateFormatService.compareDate(a.updated_at) - dateFormatService.compareDate(b.updated_at)).reverse()

            this.setState({
                listrecentpost: sortedArray,
            })
})

}
    render() {
        return (
                <div className="col-md-4">
                    <div className="sidebar right">
                        <aside id="recent-post" className=" widget widget-recent">
                            <h3 className="widget-title">Letzte Blogeinträge</h3>
                            <ul>
                                {
                                    this.state.listrecentpost.map(data => (
                                        <li key={data.id} >
                                            <Link to={`/blog-single/${data.title.replaceAll(" ","_")}`} onClick={() => {window.location.href=`/blog-single/${data.title.replaceAll(" ","_")}`}} title="">{data.title}</Link>
                                            <span>{moment(data.updated_at).format('DD.MM.YYYY')}</span>
                                        </li>
                                    ))
                                }
                            </ul>
                        </aside>
                        <aside className=" widget widget-categories">
                            <h3 className="widget-title">KATEGORIEN</h3>
                            <ul>
                                {
                                    this.state.category.map(data => (
                                        <li key={data.id} ><Link to="#" title="">{data.title}</Link></li>
                                    ))
                                }
                            </ul>
                        </aside>
                 
                    </div>
                </div>
        );
    }
}

export default SidebarBlog;
import React, { Component } from 'react';
import {Link} from 'react-router-dom';

class TopFooter extends Component {
    render() {
        return (
            <div className="widget-infomation">
                <ul className="infomation-footer">
                    <li><i className="fa fa-envelope" aria-hidden="true"></i><a href="mailto:info@glocal-it-solutions.com" title="email">info@glocal-it-solutions.com</a></li>
                    <li><i className="fa fa-phone" aria-hidden="true"></i><a href="tel:04154–80425750" title="phone">04154-80425750</a></li>
                    <li><i className="fa fa-map-marker" aria-hidden="true"></i><Link to="#" title="">Rausdorfer Str. 22, 22946 - Trittau</Link></li>
                </ul>
                {/* <!-- /.infomation-footer --> */}
            </div>
        /* <!-- /.widget-infomation --> */
        );
    }
}

export default TopFooter;
import React, { Component } from 'react';
import { Header, TopBar , Footer,  Loader } from '../layouts/general';
import { Link } from "react-router-dom";
import { SidebarBlog} from '../layouts/blog';
import { cmsService } from '../../_services/cms.service';
import moment from "moment";
import Hyphenated from 'react-hyphen';
import de from 'hyphenated-de';
class BlogGrid extends Component {
    constructor(props) {
        super(props);
        this.state = {
            headers: [
                {
                    id: 1,
                    names: 'Why Do I Need To Use Financial ?',
                }
            ],
            titleheading: [
                {
                    id: '1',
                    title: 'Why Do I Need To Use Financial ?'
                }
            ],
            breadcrumbs: [
                {
                    id: 1,
                    title: 'Home',
                    classicon: 'fa fa-angle-right',
                    aria: 'true'
                },
                {
                    id: 2,
                    title: 'Why Do I Need To Use Financial ?',
                    classicon: '',
                    aria: ''
                }
            ],
            list: [
                {
                    id: 1,
                    text: 'Customer experience, which includes the impression the homepage and overall design style give the customers their satisfaction when they interact with the site and perform tasks.'

                },
                {
                    id: 2,
                    text: 'Service-level, which looks at responsiveness and reliability of websites – scores them on how quickly they respond to user commands and such factors as average downtime.'
                },
                {
                    id: 3,
                    text: 'Best practices, such as ease of use, quality, availability and security – site managers must be compliant with data laws requiring them to protect customer information and the integrity of customer accounts.'
                }
            ],
          
            tabcloud: [
                {
                    id: '1',
                    tablink: 'Creative'
                },
                {
                    id: '2',
                    tablink: 'Portfolio'
                },
                {
                    id: '3',
                    tablink: 'ThemeForest'
                },
            ],
            selectedBlog:{},
            img:"",
            name: props.match.params.name,
        }
      
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        this.getBlogItem();
    }
    getBlogItem() {
        let { name } = this.state;
        cmsService.getBlogById(name.replaceAll("_"," ")).then(res => {
             { if (res.length == 0) {
                window.location.href='/404'
               
            }else
                this.setState({ img: res[0].image.url,selectedBlog:res[0] })
            }
        })
    }
    createMarkup(value) {
        return { __html: value };
        }
    render() {
        return (
            <Hyphenated language={de}>
            <div className="bg-body">
                <div className="boxed">
                <Loader />
                <TopBar />
                    {
                        this.state.headers.map(data => (
                            <Header data={data} key={data.id}/>
                        ))
                    }
                    <div className="page-title pages-margintop">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="page-title-heading">
                                    {this.state.selectedBlog !== null ?
                                                <h1 key={this.state.selectedBlog.id} className="h1-title">{this.state.selectedBlog.title}</h1>
                                           
                                            :
                                            null
                                        }       
                                    </div>
                                   
                                    <div className="clearfix"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="main-content">
                        <div className="container">
                            <div className="row">
                            <div className="col-md-8">
						    <div className="post-wrap">
                            {this.state.selectedBlog !== null ?
                                    <article className="main-post" key={this.state.selectedBlog.id} >
                                        
                                        <div className="featured-post">
                                            <Link to="#" title="">
                                                <img src={`${process.env.REACT_APP_CMS_URL}` + this.state.img} alt="glocal" />
                                            </Link>
                                        </div>
                                        <div className="entry-content">
                                            <p dangerouslySetInnerHTML={this.createMarkup(this.state.selectedBlog?.content)}/> <br />
                                           
                                            <ul className="list">
                                                {
                                                    this.state.list.map(data=> (
                                                        <li key={data.id}><em>{data.text}</em></li>
                                                    ))
                                                }
                                                
                                                
                                            </ul>
                                            </div>
                                            
							        </article>
                               :null
                            }
                            
						</div>
                           
                            </div>
			            
                            <SidebarBlog /></div>
                        </div>
                    </section>
                <Footer />
                </div>
            </div>
            </Hyphenated>
            
        );
    }
}

export default BlogGrid;
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import moment from "moment";
import { contactService } from '../../../_services/contact.service';
import "react-datepicker/dist/react-datepicker.css";

import de from "date-fns/locale/de"
import DatePicker,{ registerLocale  } from "react-datepicker"


registerLocale("de", de)

class TopBar extends Component {
  constructor(props) {
    super(props)
    this.captcha = null;
    this.state = {
      name: "",
      date: new Date(),
      phone_number: "",
      email: "",
      errors: {},
      valid: true,

    }

  }


  
  // on change value of input
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  }
  // handle date changes
  handleDateChange = event => {
    this.setState({ date: event });
  }
  validate() {
    let isValid = true
    let errors = {};

    if (!this.state.name) {
      errors["name"] = "Bitte geben Sie Ihren Vornamen,name ein.";
      isValid = false
    }
    if (!this.state.phone_number) {
      errors["phone_number"] = "Bitte gib deine Telefonnummer ein.";
      isValid = false
    } else if (!/[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/.test(this.state.phone_number)) {
      errors["phone_number"] = "Telefonnummer ist ungültig";
      isValid = false
    }

    if (!this.state.email) {
      errors["email"] = "Bitte geben Sie ihre E-Mail-Adresse ein.";
      isValid = false
    } else if (!/\S+@\S+\.\S+/.test(this.state.email)) {
      errors["email"] = 'E-mail Adresse ist nicht korrekt';
      isValid = false
    }

    this.setState({
      errors: errors
    });
    return isValid
  }
  mailContact() {
    let object = JSON.stringify({
      email: this.state.email,
      name: this.state.name,
      date: this.state.date,
     phone_number: this.state.phone_number,
      subject:'Glocal - Callback Formular'
     

    })

    contactService.mailContact(object).then(results => {
      swal({
        title: "Rückruftermin",
        text: "Vielen Dank für Ihre Terminanfrage. Wir werden uns bei Ihnen wie gewünscht melden!",
        dangerMode: true
      }).then((willDelete) => {
        if (willDelete) {
          window.location.href = "/"
          document.getElementById('name').value = ''
          document.getElementById('email').value = ''
          document.getElementById('phone_number').value = ''
          document.getElementById('date').value = moment(new Date()).format('DD.MM.YYYY h:mm A')


        }
      });

    }).catch(err => {

      console.error("error contact mail", err)

    });
  }
  // on submit for update
  onSubmit = event => {

    event.preventDefault();
    const isValid = this.validate()
    
    if (isValid) {
      this.mailContact();
    }
  }

 




  render() {

    return (
      <div className="top logo-desktop">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <ul className="flat-infomation">
                <li className="phone">Tel: <a href="tel:04154–80425750" title="phone">04154-80425750</a></li>
                <li className="email">E-mail: <a href="mailto:info@glocal-it-solutions.com" title="email">info@glocal-it-solutions.com</a></li>
              </ul>
              <div className="flat-questions">
                <Link to="/contact-v1" onClick={() => { window.location.href = "/contact-v1" }} title="" className="questions">Sie haben Frage?</Link>
                <a href="#"  title="" className="appointment" data-toggle="modal" data-target="#myModal">Wir rufen Sie zurück!</a>

               
                <div className="modal fade flat-modal " id="myModal" role="dialog">
                  <div className="modal-dialog ">


                    <div className="modal-content">
                      <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                        <h2 className="modal-title">Sie haben Fragen? <span className="spancolor">wir rufen Sie gerne zurück!</span></h2>
                      </div>
                      <div className="modal-body marginbottom">
                        <div className="flat-callback-form paddinglft" id="fieldform">
                          <form data-callback="6LemFQwcAAAAADHi6vVptd5lalYfb874M7VMN-H7" id="contaktform" method="post" action="./contact/contact-process2.php" noValidate="novalidate" >
                            <div className="flat-field paddingrght " >
                              <div className="field-one-half field-phone">
                                <input type="text" id="name"  name="name" placeholder="Vorname,Name" required="required" className="inputmargin"
                                  value={this.state.name}
                                  onChange={this.onChange} />
                                <div className="text-danger textmargin">{this.state.errors.name}</div>
                              </div>

                              {/* <!-- /.field-one-half --> */}
                              <div className="field-one-half field-phone">
                                <input type="text" id="email"  name="email" placeholder="Email" required="required" className="inputmargin"
                                  value={this.state.email}
                                  onChange={this.onChange} />
                                <div className="text-danger textmargin">{this.state.errors.email}</div>
                              </div>
                              {/* <!-- /.field-one-half --> */}
                              <div className="clearfix"></div>
                            </div>
                            <div className="flat-field paddingrght">
                              <div className="field-one-half field-phone">
                                <input type="text" id="phone_number"  name="phone_number" placeholder="Telefonnummer" required="required" className="inputmargin"
                                  value={this.state.phone_number}
                                  onChange={this.onChange} />
                                <div className="text-danger textmargin">{this.state.errors.phone_number}</div>
                              </div>
                              <div className="field-one-half field-phone">
                                <DatePicker
                                
                                  showTimeSelect
                                  timeFormat="HH:mm"
                                  timeIntervals={15}
                                  timeCaption="time"
                                  dateFormat=" dd, MM, yyyy h:mm aa"
                                  dropdownMode="select"
                                  selected={this.state.date}
                                  locale="de"
                                  id='date'
                                  name='date'
                                  minDate={new Date()}
                                  onChange={this.handleDateChange}
                                />
                              </div>
                              <div className="clearfix"></div>

                            </div>
                           








                            {/* <!-- /.flat-field --> */}
                          </form>
                          {/* <!-- /.form --> */}
                        </div>
                      </div>

                      <br />
                      <div className="modal-footer">
                        <button id="btnmodal" type="submit" name="submit" onClick={e => this.onSubmit(e)} className="button-submit-field" >JETZT SENDEN</button>

                      </div>
                    </div>

                  </div>



                </div>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TopBar;
export  const cmsService = {
    getHomePage,
    getAboutPage,
    getBlogs,
    getBlogById,
    getRechtlichesById,
    getServicesByname
};
 


  function getHomePage() {
   const requestOptions = {
      method: 'GET'
   };
 
   return fetch(`${process.env.REACT_APP_CMS_URL}/home-page`, requestOptions).then(handleResponse);
 } 
 function getAboutPage() {
   const requestOptions = {
      method: 'GET'
   };
 
   return fetch(`${process.env.REACT_APP_CMS_URL}/about`, requestOptions).then(handleResponse);
 } 
 function getBlogs() {
   const requestOptions = {
      method: 'GET'
   };
 
   return fetch(`${process.env.REACT_APP_CMS_URL}/blogs`, requestOptions).then(handleResponse);
 } 
 function getBlogById(name) {
   const requestOptions = {
      method: 'GET'
   };
   return fetch(`${process.env.REACT_APP_CMS_URL}/blogs?title=${name}`, requestOptions).then(handleResponse);
 } 
 
 function getRechtlichesById(id) {
   const requestOptions = {
      method: 'GET'
   };
 
   return fetch(`${process.env.REACT_APP_CMS_URL}/rechtliches/${id}`, requestOptions).then(handleResponse);
 }
 function getServicesByname(name) {
   const requestOptions = {
      method: 'GET'
   };
 
   return fetch(`${process.env.REACT_APP_CMS_URL}/services?name_for_technical_use=${name}`, requestOptions).then(handleResponse);
 }


function handleResponse(response) {
    return response.text().then(text => {
       const data = text && JSON.parse(text);
       if (!response.ok) {
          const error = (data && data.message) || response.statusText;
          return Promise.reject(error);
       }
       return data;
    });
 }
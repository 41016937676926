import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import moment from "moment";
import Hyphenated from 'react-hyphen';
import de from 'hyphenated-de';
class BlogFeatured extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    createMarkup(value) {
        return { __html: value };
    }
    render() {
        return (
            this.props.datanewBox.length > 0 ?


                <div>
                    {
                        this.props.datanewBox.slice(0, 2).map(data => (
                            <div className="col-md-4" key={data.id}>
                                <article className="post style2" >
                                    <div className="featured-post">
                                        <Link to={`/blog-single/${data.title.replaceAll(" ", "_")}`} onClick={() => { window.location.href = `/blog-single/${data.title.replaceAll(" ", "_")}` }} title="" className="post-image">
                                            <img width={370} height={210} src={`${process.env.REACT_APP_CMS_URL}` + data?.image?.formats?.small?.url}  alt={data?.image?.alternativeText} title={data?.image?.caption} />
                                        </Link>
                                        <ul className="post-date">
                                            <li className="day">{moment(data.created_at).format('DD')}</li>
                                            <li className="month">{moment(data.created_at).format('MMM')}</li>
                                        </ul>
                                    </div>
                                    {/* <!-- /.featured-post --> */}
                                    <Hyphenated language={de}>

                                        <div className="content-post">
                                            <h4 className="title-post">
                                                <Link to={`/blog-single/${data.title.replaceAll(" ", "_")}`} onClick={() => { window.location.href = `/blog-single/${data.title.replaceAll(" ", "_")}` }} title="">{data.title}</Link>
                                            </h4>
                                            <div className="entry-post">
                                                <div style={{ minHeight: "85px" }}>{data.content.length > 150 ? <p dangerouslySetInnerHTML={this.createMarkup(data.content.substring(0, 80))} /> : <p dangerouslySetInnerHTML={this.createMarkup(data.content)} />}</div>
                                            </div>
                                        </div>

                                    </Hyphenated>
                                    {/* <!-- /.content-post --> */}
                                </article>
                                {/* <!-- /.post --> */}
                            </div>
                        ))
                    }
                </div>
                : null


        );
    }
}

export default BlogFeatured;
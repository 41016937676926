const menus = [
    {
        id: 1,
        name: 'Home',
        linkmenu: '/',
    },
   
    {
        id: 2,
        name: 'Services',
        linkmenu: '#',
        namesub:  [
            {
                id: 1,
                sub: 'APP Entwicklung',
                title: 'APP Entwicklung',
                links: '/service/APP_Entwicklung',
                submenu: undefined
            },
            {
                id: 2,
                sub: 'Data Management',
                title: 'Data Management',
                links: '/service/Data_Management',
                submenu: undefined
            },
            {
                id: 3,
                sub: 'individual-software',
                title:'Individuelle SaaS-Lösung',
                links: '/service/individual-software',
                submenu: undefined
            }
        ],
    },
   {
        id: 3,
        name: 'Blog',
        linkmenu: '/blog-grid'
    },
     {
        id: 6,
        name: 'KONTAKT',
        linkmenu: '/contact-v1',
       
        
    }
]

export default menus;
import React, { Component } from 'react';
import { Header, TopBar, Footer, Loader } from '../layouts/general';
import RoomIcon from '@material-ui/icons/Room';
import swal from 'sweetalert';
import { contactService } from '../../_services/contact.service';
import {
    EReCaptchaV2Size,
    EReCaptchaV2Theme,
    ReCaptchaProvider,
    ReCaptchaV2,
} from 'react-recaptcha-x';
import { Helmet } from 'react-helmet';
const myHTML =`
<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2361.031569991626!2d9.997611315724969!3d53.71769998005856!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47b2288213208903%3A0x2bae9faec230c20f!2sUlzburger%20Str.%20352%2C%2022846%20Norderstedt%2C%20Allemagne!5e0!3m2!1sfr!2stn!4v1655382648968!5m2!1sfr!2stn"  height="450" style="border:0;width:100%;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
`
class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            headers: [
                {
                    id: 1,
                    names: 'Blog',
                }
            ],
            titleheading: [
                {
                    id: '1',
                    title: 'Kontakt'
                }
            ],
          
            contactinfo: [
                {
                    id: '1',
                    title: 'Unternehmen: ',
                    info: 'Glocal IT Solutions GmbH',
                   info1:' Handelsregister: HRB 17658 HL',
                    info2:'Registergericht: Hansestadt Lübeck',
                    info3:'Umsatzsteuer-Id: DE317044099'
                },
                
                {
                    id:'2',
                    title:'Geschäftsadresse: ',
                    info:'Rausdorfer Str. 22',
                    info1:'22946 Trittau',
                },
                {
                    id: '3',
                    title: 'Telefon:',
                    info: '04154-80425750'
                },
                {
                    id: '4',
                    title: 'E-mail',
                    info: ' info@glocal-it-solutions.com'
                }
            ],
            errors: {},
            email: "",
            first_name: "",
            last_name: "",
            message: "",
            verified: false,
        }
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
        this.handleLastNameChange = this.handleLastNameChange.bind(this);
        this.handleMessageChange = this.handleMessageChange.bind(this);
    }
    handleEmailChange = event => {
        this.setState({ email: event.target.value });
    }
    handleFirstNameChange = event => {
        this.setState({ first_name: event.target.value });
    }
    handleLastNameChange = event => {
        this.setState({ last_name: event.target.value });
    }
    handleMessageChange = event => {
        this.setState({ message: event.target.value });
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    v2Callback = (token) => {
        this.setState({
            verified: true
        });
        if (typeof token === 'string') {
        } else if (typeof token === 'boolean' && !token) {
            
        } else if (token instanceof Error) {
           
        }
    };
    mailContact() {
        let object = JSON.stringify({
            email: this.state.email,
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            message: this.state.message,
            subject:'Glocal - Neue Kontaktanfrage'

        })

        contactService.mailContact(object).then(results => {
            swal({
                title: "Kontaktanfrage",
                text: "Vielen Dank für Ihre Anfrage. Wir melden uns Zeitnahe bei Ihnen!",
                dangerMode: true
            })
                .then((willDelete) => {
                    if (willDelete) {
                        window.location.href = "/contact-v1"
                        document.getElementById('name').value = ''
                        document.getElementById('lastname').value = ''
                        document.getElementById('email').value = ''
                        document.getElementById('message').value = ''

                    }
                });

        }).catch(err => {

            console.error("error contact mail", err)

        });
    }
    onSubmit = (e) => {
        e.preventDefault();
        if (this.validate()) {
            this.mailContact();
        }
    }

    validate() {

        let errors = {};
        let isValid = true;
        let email = this.state.email;
        let message = this.state.message;
        let first_name = this.state.first_name;
        let last_name = this.state.last_name;
        if (!message) {
            isValid = false;
            errors["message"] = "Bitte geben Sie Ihren Message ein";
        }
        if (!first_name) {
            isValid = false;
            errors["first_name"] = "Bitte geben Sie Ihren Namen ein";
        }
        if (!last_name) {
            isValid = false;
            errors["last_name"] = "Bitte geben Sie Ihren Vornamen ein";
        }
        if (!email) {
            isValid = false;
            errors["email"] = "Bitte geben E-Mailadresse ein";
        }

        if (typeof email !== "undefined") {

            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(email)) {
                isValid = false;
                errors["email"] = "Bitte geben Sie eine gültige E-Mailadresse ein";
            }
        }
        this.setState({
            errors: errors
        });
        return isValid;
    }
    
    render() {
      
        return (
            <div className="bg-body">
                <div className="boxed">
                    <Loader />
                    <Helmet>
                    <title>Kontakt - GLOCAL IT SOLUTIONS</title>
                    <meta name="description" content="Kontakt - GLOCAL IT SOLUTIONS"></meta>
                    <meta name="robots" content="index ,follow" />
                </Helmet>
                    <TopBar />
                    {
                        this.state.headers.map(data => (
                            <Header data={data} key={data.id} />
                        ))
                    }

                    <div className="page-title pages-margintop">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="page-title-heading">
                                        {
                                            this.state.titleheading.map(data => (
                                                <h1 key={data.id} className="h1-title">{data.title}</h1>
                                            ))
                                        }
                                    </div>
                                    
                                    <div className="clearfix"></div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <section className="flat-row pd-contact-v1">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="contact-info">
                                        {
                                            this.state.contactinfo.map(data => (
                                                <div className="info info-address" key={data.id} >
                                                    <div className="title">{data.title}</div>
                                                    <p>{data.info} </p>
                                                    {data?.info1 ||data?.info2 ||data?.info3?
                                                    <p>{data?.info1} <br/>{data?.info2}  <br/> {data?.info3}</p>:null}
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>

                                <div className="col-md-8">
                                    <h1 style={{ color: "black" }}>HABEN SIE <span style={{ color: "#de472b" }}>FRAGEN ODER ANREGUNGEN?</span></h1>
                                    <p style={{ marginBottom: "15px", marginTop: "15px" }}>Egal was Ihnen auf der Zunge liegt oder im Kopf rumschwirrt, darüber können wir gerne reden. <span style={{ color: "#de472b" }}>Schreiben Sie uns einfach an. Wir melden uns bei Ihnen umgehend.</span> </p>
                                    <div className="flat-form-info">
                                        <form id="contactform" method="post" action="./contact/contact-process.php" className="form-info">
                                            <div className="one-half v3">
                                                <div className="input-info inputmargin"><input type="text" name="vorname" id="lastname" placeholder="Vorname" errormessage="Enter Your email"
                                                    value={this.state.last_name}
                                                    onChange={this.handleLastNameChange}
                                                    required="required" />
                                                    <div className="text-danger textmargin">{this.state.errors.last_name}</div></div>
                                                <div className="input-info inputmargin"><input type="text" name="name" id="name" placeholder="Name" required="required"
                                                    value={this.state.first_name}
                                                    onChange={this.handleFirstNameChange}
                                                />
                                                    <div className="text-danger textmargin">{this.state.errors.first_name}</div>
                                                </div>
                                                <div className="input-info inputmargin"><input type="email" name="email" id="email" placeholder="E-mail" required="required"
                                                    onChange={this.handleEmailChange}
                                                    value={this.state.email}
                                                />
                                                    <div className="text-danger textmargin">{this.state.errors.email}</div>
                                                </div>
                                                <div className="input-info inputmargin">
                                                    <ReCaptchaProvider
                                                        siteKeyV2="6LcLgN4cAAAAAPXZ3a23Ae9gtqqIJaQSgJL7Kauc"
                                                        langCode="de"

                                                    >
                                                        <ReCaptchaV2
                                                            callback={this.v2Callback}
                                                            theme={EReCaptchaV2Theme.Light}
                                                            size={EReCaptchaV2Size.Normal}
                                                            id="kontakt"
                                                            data-test-id="kontakt"
                                                            tabindex={0}
                                                        />
                                                    </ReCaptchaProvider>
                                                </div>
                                                 </div>
                                            <div className="one-half v4">
                                                <div className="input-info inputmargin"><textarea id="message" name="message" placeholder="Nachricht" required="required" value={this.state.message} onChange={this.handleMessageChange}></textarea>
                                                    <div className="text-danger textmargin">{this.state.errors.message}</div>
                                                </div>
                                                <div className="input-info"><button id="btncontact" type="submit" className="submit" onClick={e => this.onSubmit(e)} disabled={!this.state.verified}>JETZT SENDEN</button></div>
                                          
                                            </div>
                                        </form>
                                    </div>
                                </div>

                            </div>
                        </div>
                    <div dangerouslySetInnerHTML={{ __html: myHTML }} style={{marginTop:"2rem"}} />

                    </section>

                    <Footer />
                </div>
            </div>
        );
    }
}

export default Contact;
import React, { Component } from 'react';
import {Link} from 'react-router-dom';
class UsefulLink extends Component {
    render() {
        return (
            <div className="widget widget-services">
                <ul className="one-half first">
                {/* <li><Link to="/about-v3" onClick={() => {window.location.href="/about-v3"}} title="">Über Uns</Link></li>
                  */}  
                     <li><Link to="/service/APP_Entwicklung" onClick={() => {window.location.href="/service/APP_Entwicklung"}}>APP Entwicklung</Link></li>
                    <li><Link to="/service/Data_Management" onClick={() => {window.location.href="/service/Data_Management"}} title="">Data Management</Link></li>
                    <li><Link to="/service/individual-software" onClick={() => {window.location.href="/service/individual-software"}} title="">Individuelle SaaS-Lösung</Link></li>
                    
                </ul>
                {/* <!-- /.one-half --> */}
                <ul className="one-half">
                
                   
                    <li><Link to="/contact-v1" onClick={() => {window.location.href="/contact-v1"}} title="Kontakt">Kontakt</Link></li>
                    <li><Link to="/impressum" onClick={() => {window.location.href="/impressum"}} title="Impressum">Impressum</Link></li>
                    <li><Link to="/datenschutzerklarung" onClick={() => {window.location.href="/datenschutzerklarung"}} title="Datenschutzerklärung">Datenschutzerklärung</Link></li>
                    <li><Link to="/agb" onClick={() => {window.location.href="/agb"}} title="AGB">AGB</Link></li>
                </ul>
                {/* <!-- /.one-half --> */}
            </div>
        /* <!-- /.widget-services --> */
        );
    }
}

export default UsefulLink;
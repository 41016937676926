import React, { Component } from 'react';
import { Header, TopBar ,  Loader } from '../layouts/general';
import { SingleBlogComponent } from '../layouts/blog';
class BlogGrid extends Component {
    constructor(props) {
        super(props);
        this.state = {
            headers: [
                {
                    id: 1,
                    names: 'Blog Gird',
                }
            ],
            titleheading: [
                {
                    id: '1',
                    title: 'Blog'
                }
            ],
         
        }
    }
    render() {
        return (
            <div className="bg-body">
                <div className="boxed">
                <Loader />
                <TopBar />
                    {
                        this.state.headers.map(data => (
                            <Header data={data} key={data.id}/>
                        ))
                    }
                <div className="page-title pages-margintop">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="page-title-heading">
                                        {
                                            this.state.titleheading.map(data =>(
                                                <h1 key={data.id} className="h1-title">{data.title}</h1>
                                            ))
                                        }       
                                    </div>
                                   
                                    <div className="clearfix"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                <SingleBlogComponent />

                
                </div>
            </div>
            
        );
    }
}

export default BlogGrid;
import React, { Component } from 'react'
import {Link} from 'react-router-dom'
class SkillBox2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
           
        }
    }
    createMarkup(value) {
        return { __html: value }
    }
    render() {
        return (
            <div className="row">
                {
                    this.props.skills.carts.length>0 && this.props.skills.carts.slice(3,6).map(data => (
                        <div className="col-md-4" key={data.id}>
                            <div className="iconbox-item">
                                <div className="iconbox style1">
                                    <div className="box-header">
                                    <div className="icon-rounded  "  style={{ backgroundImage: `url(${process.env.REACT_APP_CMS_URL + data?.classicon?.url}`}} >
                                       
                                        </div>
                                        {/* <!-- /.icon-rounded --> */}
                                        <div className="box-title" style={{marginLeft:"15px"}}>
                                            <Link to="#" title="">{data.title}</Link>
                                        </div>
                                        {/* <!-- /.box-title --> */}
                                        </div>
                                        {/* <!-- /.box-header --> */}
											<div style={{marginLeft:"15px"}} className="pfontsize box-content" id="bestserv" >
                                               
                                                <p className="textAlign" dangerouslySetInnerHTML={this.createMarkup(data.boxcontent)} />
                                               

                                  </div>
                                    {/* <!-- /.box-content --> */}
                                </div>
                                 {/* <!-- /.iconbox --> */}
						    </div>
                            {/* <!-- /.iconbox-item --> */}
                        </div>
                     ))
                }
            </div>
        );
    }
}

export default SkillBox2;
import React, { Component } from 'react';
import {Link} from 'react-router-dom'
class BottomBar extends Component {
    render() {
        return (
            <div className="footer-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="copyright copyright-p">
                                    <p>Copyright© 2021 —<Link to="#" title="">GLOCAL IT SOLUTIONS.</Link> All rights reserved.</p>
                                </div>
                              
                            </div>
                        </div>
                    </div>
                </div>
                /* <!-- /.footer-bottom --> */
        );
    }
}

export default BottomBar;
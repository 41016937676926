import React, { Component } from 'react';
import FormApply from "./FormApply";
class Callback extends Component {
    constructor(props) {
        super(props);
        this.state = {
          
           
        }
    }
    componentDidMount(){
	}
	
	  createMarkup(value) {
        return { __html: value };
    }
    render() {
        return (
        this.props.contactdata.Contact ?
            <section className="flat-row flat-callback mrgn-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                                    <div className="text-block-callback" key={this.props.contactdata.Contact?.id}>
                                        <h2>{this.props.contactdata.Contact?.title1} <span style={{color:"#de472c"}}>{this.props.contactdata.Contact?.title2}</span></h2>
                                        
                                        <div dangerouslySetInnerHTML={this.createMarkup(this.props.contactdata.Contact?.subtitle)} className="text-callback-content" />
                                    </div>
                               
                        </div>
                        <div className="col-md-8">
                            <FormApply />
                        </div>
                    </div>
                </div>
            </section>
            :null
        );
    }
}

export default Callback;
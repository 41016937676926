import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import {  HeaderTransparent, Slider, Loader, CarouselClient, TopBar } from '../layouts/general';
import { ServiceSection } from '../layouts/general/serviceSection'
import { Meta } from '../layouts/general/meta';
import { cmsService } from '../../_services/cms.service';

const myHTML = `
<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2361.031569991626!2d9.997611315724969!3d53.71769998005856!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47b2288213208903%3A0x2bae9faec230c20f!2sUlzburger%20Str.%20352%2C%2022846%20Norderstedt%2C%20Allemagne!5e0!3m2!1sfr!2stn!4v1655382648968!5m2!1sfr!2stn"  height="450" style="border:0;width:100%;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
`
class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            headers: [
                {
                    id: 1,
                    names: 'Home'
                }
            ],

            homepage: {},
         
        }
        this.gethomepage()
    }
  
    gethomepage() {
        cmsService.getHomePage().then(res => {
            this.setState({ homepage: res })
        })

    }
    render() {

        return (
            <div className="bg-body3">
                <div className="boxed">

                    <Meta metaData={this.state?.homepage?.Section1} />
                    <TopBar />
                    {
                        this.state.headers.map(data => (
                            <HeaderTransparent data={data} key={data.id} />
                        ))
                    }
                    
                    <Slider />
                    <Loader />
                    {this.state.homepage.Section3 ?
                        <>

                            <ServiceSection servicedata={this.state?.homepage} />



                        </>
                        : null
                    }

                    <CarouselClient clientdata={this.state.homepage} />


                </div>
            </div>
        );
    }
}

export default withRouter(Home);
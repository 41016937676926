import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import Hyphenated from 'react-hyphen';
import de from 'hyphenated-de';
class BlogFeatured extends Component {
    constructor(props) {
        super(props);
        this.state = {
            titleServices: [
                {
                    id: 0,
                    title1: '',
					title2: '',
					subtitle:''}
            ],
            datanewBox: [
                {
                    id: 0,
                    title: 'ANALYSIEREN',
                    description: 'Gemeinsam analysieren wir mit Ihnen die Projektziele und unterstützen Sie bei der Projektplanung. Dabei werden die Prozesse analysiert und die Anwenderanforderungen an die Software aufgenommen. Die aufgenommen Anforderungen fließen in ein Pflichten- bzw. Lastenheft ein und werden anschließend detailliert mit Ihnen abgestimmt. Im Rahmen der Analyse erfolgt zusätzlich eine Bedarfsprüfung. Bei der Bedarfsprüfung ob zusätzlicher Digitalisierungsbedarf in Ihrem Unternehmen besteht (Hardware, Support, Service etc.). ',
                   url:'images/imagebox/analyse.webp'
                } ,
                 {
                    id: 1,
                    title: 'EVALUIEREN',
                    description: 'Im Rahmen der Evaluierung wird der Projektumfang in Abhängigkeit von Budget und Realisierungszeit geprüft und festgelegt.  Es werden die nötigen Ressourcen definiert und zugewiesen. Die erforderliche Projektstruktur wird abgeleitet und die Projektorganisation aufgesetzt. Anschließend wird Ihnen der Bericht zum Evaluierungsergebnis zur Freigabe vorgelegt. ',
                url:'images/imagebox/evaluate project.webp'

                 } ,
                 {
                    id: 2,
                    title: 'AUFTRAGSUMSETZUNG ',
                    description: 'Sind Pflichtenheft, Budget und Lieferzeit abgestimmt, beginnen wir mit der Projektumsetzung. Dabei erfolgt die Auftragsabwicklung agil (SCRUM). Ein Projektmanager wird dem Auftrag zugewiesen und ist für Sie der zentrale Ansprechpartner für alle Ihre Belangen. Wir halten unsere Abläufe für Sie transparent. Wir nutzen für die Auftragsplanung das Projektmanagement-Tool Jira, so dass Sie jederzeit die Möglichkeit haben, den Projektstand einzusehen. Sämtliche von uns entwickelte Software ist ausführlich dokumentiert. Dadurch ist eine spätere Anpassung bzw. Erweiterung unabhängig von uns problemlos möglich. Es erfolgt begleitend zu der Projektumsetzung eine kontinuierliche Qualitätsprüfung. Zu der Qualitätsprüfung Dadurch stellen wir sicher, dass nur einwandfreie Software übergeben wird. ',
                url:'images/imagebox/team develop.webp'

                 } ,
                 {
                    id: 3,
                    title: 'SUPPORT UND WARTUNG',
                    description: 'Auch nach Übergabe des Projektes stehen wir Ihnen mit Wartung und Support zur Seite. Viele Produkte erfordern eine regelmäßige Wartung. Wir sorgen dafür, dass Ihre Software immer einsatzbereit und aktuell bleibt. Auch für Softwareerweiterungen und -verbesserungen stehen wir Ihnen zur Verfügung. Wir informieren regelmäßig über die neusten Entwicklungen und geben Ihnen somit die Möglichkeit, Ihre Infrastruktur auf dem neusten Stand zu halten. ',
                url:'images/imagebox/project maintenance.webp'

                 } 
                ],
           
        }
    }
    componentDidMount(){
	}
  
	  createMarkup(value) {
        return { __html: value };
    } 
    showalltext(e){
        const text=document.getElementById(e)
        text.classList.toggle("full");
      
    }   
    render() {
        return (
            this.props.supportdata.Section2?
            <>
               <div className="col-md-12" >
                                <div className="title-section left">
                                    <h2>{this.props.supportdata.Section2?.title1} <span style={{color:"#de472c"}}>{this.props.supportdata.Section2?.title2}</span></h2>
                                </div>
                                <Hyphenated language={de}>

                              <p className="textAlign service-p" dangerouslySetInnerHTML={this.createMarkup(this.props.supportdata.Section2?.subtitle)}  />
                               </Hyphenated>
                                {/* <!-- /.title-section --> */}
                        </div>
            
				<div className='col-md-12'>		
                <div className='row'>	
                         
             {
                this.props.supportdata.Section2.Cards.length>0 && this.props.supportdata.Section2.Cards.slice(0,2).map((data,i) => (
                    
                    <div className="col-md-6" key={i} style={{margin:"1rem 0rem"}}>
                        <div className="imagebox-item">
                            <div className="imagebox style2">
                                <div className="imagebox-image skill-margin">
                                    <img src={`${process.env.REACT_APP_CMS_URL}` + data?.image?.url} alt={data?.name}  width={570} height={350}/>
                                </div>
                                <div className="imagebox-title">
                                    <h3><Link to="#"  title="" style={{cursor:'default'}}>{data.title}</Link></h3>
                                </div>
                                <Hyphenated language={de}>

                                <div className="imagebox-content">
                                
                                 
                                    <div  className="imagebox-desc" id={"trim"+i} onClick={()=>this.showalltext("trim"+i)}  dangerouslySetInnerHTML={this.createMarkup(data.description)}>

                                    </div>
                                     
                                </div>
                                </Hyphenated>
                            </div>
                        </div>
                    </div>
                   
                ))
                }
                 </div>
                 </div>
                 
				<div className='col-md-12'>		
                <div className='row'>	
                {
                this.props.supportdata.Section2.Cards.length>0 && this.props.supportdata.Section2.Cards.slice(2,4).map((data,i) => (
                    
                    <div className="col-md-6" key={i} style={{margin:"1rem 0rem"}}>
                        <div className="imagebox-item">
                            <div className="imagebox style2">
                                <div className="imagebox-image skill-margin">
                                    <img src={`${process.env.REACT_APP_CMS_URL}` + data?.image?.url} alt={data?.name}  width={570} height={350}/>
                                </div>
                                <div className="imagebox-title">
                                    <h3><Link to="#"  title="" style={{cursor:'default'}}>{data.title}</Link></h3>
                                </div>
                                <Hyphenated language={de}>

                                <div className="imagebox-content">
                                
                                 
                                    <div  className="imagebox-desc" id={"trim"+(2+i)} onClick={()=>this.showalltext("trim"+(2+i))}  dangerouslySetInnerHTML={this.createMarkup(data.description)}>

                                    </div>
                                     
                                </div>
                                </Hyphenated>
                            </div>
                        </div>
                    </div>
                ))
                }
                </div>
                </div>
                </>
                :null
        
                               
        );
    }
}

export default BlogFeatured;
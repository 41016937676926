import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import Hyphenated from 'react-hyphen';
import de from 'hyphenated-de';
import Skills from '../services/Skills';
import { BlogFeatured, Counter } from '../../home05';
import { Blog } from '../blog';
import { Callback } from '../callback';
const myHTML =`
<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2361.031569991626!2d9.997611315724969!3d53.71769998005856!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47b2288213208903%3A0x2bae9faec230c20f!2sUlzburger%20Str.%20352%2C%2022846%20Norderstedt%2C%20Allemagne!5e0!3m2!1sfr!2stn!4v1655382648968!5m2!1sfr!2stn"  height="450" style="border:0;width:100%;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
`
class ServiceSection extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    redirectToservice(e) {
        window.location.href = `/service/${e.replaceAll(" ", "_")}`
    }
    createMarkup(value) {
        return { __html: value };
    }
    render() {
        return (
            this.props.servicedata.Section3 ?
            <>
                <section className="flat-row flat-imagebox pd-imagebox-s3">
                    <div className="container">
                        <div className="row" >
                            <div className="col-md-12" >
                                <div className="title-section left">
                                    <h1>UNSER <span style={{ color: "#de472c" }}> SERVICE</span></h1>
                                </div>

                                {/* <!-- /.title-section --> */}

                            </div>

                        </div>
                        <div className="row">
                            {
                                this.props.servicedata.Section3.ImageCard.length > 0 && this.props.servicedata.Section3.ImageCard.map(data => (
                                    <div className="col-md-4" key={data.id} >
                                        <div className="imagebox-item">
                                            <div className="imagebox style3 background-color">
                                                <div className="imagebox-image" onClick={() => this.redirectToservice(data.Name_for_technical_use)} style={{ cursor: "pointer" }}>
                                                    <img src={`${process.env.REACT_APP_CMS_URL}` + data?.Image?.url} alt={data?.name} width={380} height={250} />
                                                </div>
                                                <Hyphenated language={de}>
                                                    <>
                                                        <div className="imagebox-title">
                                                            <h3>
                                                                <Link to={`/service/${data.Name_for_technical_use.replaceAll(" ", "_")}`} onClick={() => { window.location.href = `/service/${data.Name_for_technical_use.replaceAll(" ", "_")}` }} title="">{data.title}</Link>

                                                            </h3>
                                                        </div>
                                                        <div className="imagebox-content">
                                                            <div className="imagebox-desc textAlign" dangerouslySetInnerHTML={this.createMarkup(data.description)} />
                                                        </div>
                                                    </>
                                                </Hyphenated>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    </section>
                    <Skills skilldata={this.props.servicedata} />
                    <Counter />
                    <section className="flat-row flat-imagebox style2 bg-theme">
                        <div className="container">
                            <div className="row">

                                <BlogFeatured supportdata={this.props.servicedata} />

                            </div>
                        </div>
                    </section>
                    {/*  <Project />  */}

                    <Blog />
                    <Callback contactdata={this.props.servicedata} />
                    <section className="flat-row pdmap">
                    <div dangerouslySetInnerHTML={{ __html: myHTML }} />
                    </section>
                    
                    </>
                : null

        );
    }
}

export default ServiceSection;
import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import menus from '../menu'
class HeaderTransparent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			linklogo: '/',
		}
	}
	scrollFunction() {
		var header = document.getElementById("header");
		var logo = document.getElementById("logostick");
		var logo2=document.getElementById("logo2")
		var sticky = header.offsetTop;
		if (window.pageYOffset > sticky) {
			header.classList.add("stickyheader");
			logo.classList.add("logostick");
			logo.classList.remove("logostickno");
			logo2.classList.add("logostickno");
			logo2.classList.remove("logostick");



		} else {
			header.classList.remove("stickyheader")
			logo.classList.remove("logostick");
			logo2.classList.remove("logostickno");
			logo2.classList.add("logostick");
			logo.classList.add("logostickno");



		}
	}

	render() {
		const { location } = this.props;
		window.addEventListener('scroll', this.scrollFunction);
		return (
			<div>
				<div id="header" className="header transparent style3 logo-desktop ">
					<div className="container">
						<div className="row">
							<div className="header-wrap">
								<div className="col-md-2">
									<div >
										<Link to="#" title="">
												<img width={170} height={85} src="images/logo-blog.webp" className="logostickno" alt=" glocal it solutions " title=" glocal it solutions "  id="logostick" />

												<img width={170} height={85} src="images/logo-glocal.webp" alt=" glocal it solutions " title=" glocal it solutions "   className='logostick' id="logo2"/>


										</Link>
									</div>
								</div>
								<div className="col-md-10">
									<div className="nav-wrap">
										<nav id="mainnav" className="mainnav" >
											<ul className="menu">
												{
													menus.map(data => (
														<li className={data.name === this.props.data.names ? "active" : ""} key={data.id}>
															{
																data.namesub !== undefined ?
																	<Link to={data.linkmenu} onClick={() => { window.location.href = data.linkmenu }}>{data.name} <i className="fa fa-angle-down" aria-hidden="true"></i></Link>
																	:
																	<Link to={data.linkmenu} onClick={() => { window.location.href = data.linkmenu }}>{data.name} </Link>
															}

															{
																data.namesub === undefined ? "" :
																	<ul className="sub-menu">
																		{
																			data.namesub.map(submenus => (
																				<li className={location.pathname === submenus.links || submenus.sub === this.props.data.names02 ? "active" : ""} key={submenus.id}><Link to={`/service/${submenus.sub.replaceAll(" ", "_")}`} onClick={() => { window.location.href = `/service/${submenus.sub.replaceAll(" ", "_")}` }}>{submenus.title}</Link>
																					{
																						submenus.submenu === undefined ? "" :
																							<ul className="sub-menu">
																								{
																									submenus.submenu.map(menusub => (
																										<li className={location.pathname === menusub.linksub ? "active" : ""} key={menusub.id}><Link to={menusub.linksub} onClick={() => { window.location.href = menusub.linksub }}>{menusub.titlesub}</Link></li>
																									))
																								}
																							</ul>
																					}
																				</li>
																			))
																		}
																	</ul>
															}
														</li>
													))
												}
											</ul>
										</nav>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div id="header-mobile" className="header transparent style3  stickyheadermobile logo-mobile">
					<div className="container">
						<div className="row">
							<div className="header-wrap">
								<div className="col-md-2">
									<div id="logo" className="logo">
										<Link to="#" title="">
											<img src="images/logo-blog.webp" alt="logo glocal it solutions " />
										</Link>
									</div>
								</div>
								<div className="col-md-10">
									<div className="nav-wrap">
										<div className="btn-menu">
											<span></span>
										</div>
										<nav id="mainnav" className="mainnav mainnavmobile" >
											<ul className="menu">
												{
													menus.map(data => (
														<li className={data.name === this.props.data.names ? "active" : ""} key={data.id}>
															{
																data.namesub !== undefined ?
																	<Link to={data.linkmenu} onClick={() => { window.location.href = data.linkmenu }}>{data.name} <i className="fa fa-angle-down" aria-hidden="true"></i></Link>
																	:
																	<Link to={data.linkmenu} onClick={() => { window.location.href = data.linkmenu }}>{data.name} </Link>
															}

															{
																data.namesub === undefined ? "" :
																	<ul className="sub-menu">
																		{
																			data.namesub.map(submenus => (
																				<li className={location.pathname === submenus.links || submenus.sub === this.props.data.names02 ? "active" : ""} key={submenus.id}><Link to={`/service/${submenus.sub.replaceAll(" ", "_")}`} onClick={() => { window.location.href = `/service/${submenus.sub.replaceAll(" ", "_")}` }}>{submenus.title}</Link>
																					{
																						submenus.submenu === undefined ? "" :
																							<ul className="sub-menu">
																								{
																									submenus.submenu.map(menusub => (
																										<li className={location.pathname === menusub.linksub ? "active" : ""} key={menusub.id}><Link to={menusub.linksub} onClick={() => { window.location.href = menusub.linksub }}>{menusub.titlesub}</Link></li>
																									))
																								}
																							</ul>
																					}
																				</li>
																			))
																		}
																	</ul>
															}
														</li>
													))
												}
											</ul>
										</nav>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

			</div>

		);
	}
}

export default withRouter(HeaderTransparent);
import React, { Component } from 'react';
import { Header, TopBar, Footer } from '../../../layouts/general';
import { Link } from "react-router-dom";
import { cmsService } from '../../../../_services/cms.service';
import { Helmet } from 'react-helmet';


class ServiceOverview extends Component {
    constructor(props) {
        super(props);
        
        this.state = {

            menutab4: [
                {
                    id: 1,
                    title: 'Was machen wir?',
                    class: 'active',

                },
                {
                    id: 2,
                    title: 'Wo kommt unsere Dienstleistung zum Einsatz?',
                },
                {
                    id: 3,
                    title: 'Datensammlung',

                },
                {
                    id: 4,
                    title: 'Datenanalyse',
                },
                {
                    id: 5,
                    title: 'Datenverwaltung',
                },
                {
                    id: 6,
                    title: 'Daten anbinden',
                }, {
                    id: 6,
                    title: 'Daten anbinden',
                }],

            menutab2: [
                {
                    id: 1,
                    title: '',
                    class: 'active',

                },
                {
                    id: 2,
                    title: '',
                },
                {
                    id: 3,
                    title: '',

                },
                {
                    id: 4,
                    title: '',
                },
                {
                    id: 5,
                    title: '',
                },
                {
                    id: 6,
                    title: '',
                },
                {
                    id: 7,
                    title: '',
                },
                {
                    id: 8,
                    title: '',
                },
                {
                    id: 9,
                    title: '',
                },
                {
                    id: 10,
                    title: '',
                },
                {
                    id: 11,
                    title: '',
                },
                {
                    id: 12,
                    title: '',
                },





            ],
            tabpost: [

                {
                    id: '1',
                    classcategory: 'wrap-one-half services',
                },
                {
                    id: '2',
                    classcategory: 'wrap-one-half services',
                },
                {
                    id: '3',
                    classcategory: 'wrap-one-half services',
                },
                {
                    id: '4',
                    classcategory: 'wrap-one-half services',
                },
                {
                    id: '5',
                    classcategory: 'wrap-one-half services',
                },
                {
                    id: '6',
                    classcategory: 'wrap-one-half services',
                },
                {
                    id: '7',
                    classcategory: 'wrap-one-half services',
                },
            ],
            boxcontent: [],
            headers: [
                {
                    id: 1,
                    names: 'Pages'
                }
            ],
            name: window.location.href.split('/')[4],
            data: [],
            meta: [],
            expanded: '1',

        }
       
        this.getServiceByName();
    }
    componentDidMount() {
        window.scrollTo(0, 0);

    }
    getServiceByName() {
        let { name } = this.state;
        cmsService.getServicesByname(name.replaceAll("_", " ")).then(res => {
            if (res.length == 0) {
                window.location.href='/404'
               
            }
            else
            {
                this.setState({ menutab: res[0].branches, boxcontent: res[0].branches, data: res[0] })

                let i = 1;
                let test = []
                res[0].branches.forEach(e => {
                    let object = {
                        id: i,
                        title: e.title,
                        crm_id: e.id

                    }
                    if (i === 1) {
                        object.class = "active"
                    }
                    i++;
                    test.push(object)
                })
                this.setState({ menutab2: test })
                if (res[0].meta)
                    this.setState({ meta: res[0].meta })

            }

        })
    }
    createMarkup(value) {
        return { __html: value };
    }
    render() {

        const { data, meta } = this.state;
        return (
            <div className="bg-body3">
                <div className="boxed">
                    <TopBar />
                    <Helmet>
                        <title>{meta?.metaTitle}</title>
                        <meta name="description" content={meta?.metaDesc} />
                        <meta name="robots" content="index ,follow" />
                    </Helmet>
                    {
                        this.state.headers.map(data => (
                            <Header data={data} key={data.id} />
                        ))
                    }

                    <div className="page-title pages-margintop">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="page-title-heading">
                                        <h1 className="h1-title">{data.title}</h1>
                                    </div>

                                    <div className="clearfix"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="flat-row pd-services-widget">
                        <div className="container">
                            <div className="row flat-tabs" data-effect="fadeIn">
                                <div className="col-md-3" style={{ position: "sticky", top: "2rem" }}>
                                    <div className="sidebar left">
                                        <aside className="widget widget_nav_menu ">
                                            <div className="menu-services-container ">
                                                <ul className="menu menu-tab " >

                                                    {
                                                        this.state.menutab2?.map(data => (
                                                            <li className={data.class} key={data.id} ><Link to="#" >{data.title}</Link>

                                                            </li>
                                                        ))

                                                    }

                                                </ul>
                                            </div>
                                        </aside>

                                    </div>
                                </div>

                                <div className="col-md-9 content-tab">


                                    {
                                        this.state.menutab2.map(data => (
                                            <div className="content-inner" key={data.id} >
                                                <div className="wrap-one-half services">
                                                    <div className="v1">
                                                        <div className="box-content">
                                                            {
                                                                this.state.boxcontent?.map((item) => (
                                                                    <div key={item.id}>

                                                                        {item.id === data.crm_id ?
                                                                            <div id={item.id} >
                                                                                        
                                                                                <div id="serv" dangerouslySetInnerHTML={this.createMarkup(item.content)} />


                                                                            </div>
                                                                            : null}
                                                                    </div>
                                                                ))

                                                            }

                                                        </div>
                                                    </div>



                                                </div>
                                            </div>

                                        ))
                                    }






                                </div>











                            </div>
                        </div>
                    </section>
                    <Footer />
                </div>
            </div>
            )


    }
}

export default (ServiceOverview)
import React, { Component } from 'react';

import { Grid, Typography, Box, Container } from '@material-ui/core';
import image404 from "./404-img.png"
import { Helmet } from 'react-helmet';


class NotFound extends Component {
	render() {
	return (
		<div className="page-404">
			<div className="page-space-top">
				<Container>
				<Helmet>
				<title>404: Diese Seite existiert nicht bei uns</title>
				<meta name="robots" content="index" />
                    </Helmet>
					<Box className="">
						<Grid container justify="center" alignItems="center" spacing={3} direction="row" className="vh-100 text-center">
							<Grid item xs={12} sm={7} className="page-404-img-wrap">
								<div className="page-404-img">
									<img src={image404} alt="Error 400" title="Error 400" className="img-fluid" />
								</div>
								<div className="page-404-content">
									<Typography variant="h5">
									Not Found
									</Typography>
									
									
								</div>
							</Grid>
						</Grid>
					</Box>
				</Container>
			</div>
		</div>
	);
	}
}

export default NotFound;
import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { cmsService } from '../../../_services/cms.service';
import { dateFormatService } from '../../../_services/dateFormat.service';
import moment from "moment";
import { Footer } from '../general';

class SingleBlogComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pagination: [
                {
                    id: 1,
                    numb: '1',
                    class: 'active'
                },
                {
                    id: 2,
                    numb: '2',
                },
                {
                    id: 3,
                    numb: '>',
                }
            ],
            datablog: []


        }
        this.getblogpage()
    }
    getblogpage() {
        cmsService.getBlogs().then(res => {

            const sortedArray = res.sort((a, b) => dateFormatService.compareDate(a.updated_at) - dateFormatService.compareDate(b.updated_at)).reverse()

            this.setState({
                datablog: sortedArray,
            })
        })

    }
    createMarkup(value) {
        return { __html: value };
    }
    render() {
        return (
            this.state.datablog.length>0?
<>
            <section className="main-content blog-single-post">
                <div className="container">
                    <div className="row">
                        <div className="post-wrap">
                            {
                                this.state.datablog.map(data => (
                                    <div className="col-md-4" key={data.id} >
                                        <article className="post style2">
                                            <div className="featured-post">
                                                <Link to={`/blog-single/${data.title.replaceAll(" ", "_")}`} onClick={() => { window.location.href = `/blog-single/${data.title.replaceAll(" ", "_")}` }} title="" className="post-image">
                                                    <img src={`${process.env.REACT_APP_CMS_URL}` + data?.image?.formats?.small.url} alt={ data?.image?.alternativeText} width={370} height={210} />
                                                </Link>
                                                {data.image ?
                                                <ul className="post-date">
                                                    <li className="day">{moment(data.created_at).format('DD')}</li>
                                                    <li className="month">{moment(data.created_at).format('MMM')}</li>
                                                </ul>:null}
                                            </div>
                                            {data.image ?
                                            <div className="content-post">
                                                <h4 className="title-post">
                                                    <Link to={`/blog-single/${data.title.replaceAll(" ", "_")}`} onClick={() => { window.location.href = `/blog-single/${data.title.replaceAll(" ", "_")}` }} title="">{data.title}</Link>

                                                </h4>
                                                <div className="entry-post">
                                                    <div style={{ minHeight: "85px" }}>{data.content.length > 150 ? <div dangerouslySetInnerHTML={this.createMarkup(data.content.substring(0, 100))} /> : <div dangerouslySetInnerHTML={this.createMarkup(data.content)} />}</div>

                                                    <div className="more-link">
                                                        <Link to={`/blog-single/${data.title.replaceAll(" ", "_")}`} onClick={() => { window.location.href = `/blog-single/${data.title.replaceAll(" ", "_")}` }} title="">Mehr Erfahren..</Link>
                                                    </div>
                                                </div>
                                            </div>
                                            :null}
                                        </article>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                  {/*   <div className="row">
                        <div className="col-md-12">
                            <div className="dividers dividers-pagination"></div>
                            <div className="blog-single-pagination">
                                <ul className="flat-pagination">
                                    {
                                        this.state.pagination.map(data => (
                                            <li key={data.id} ><Link to="#" className={data.class} title="">{data.numb}</Link></li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                    </div> */}
                </div>
            </section>
            <Footer />
            </>
            :null
        );
    }
}

export default SingleBlogComponent;
import React, { Component } from 'react';
import Footer from './Footer';

class CarouselClient extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imgCarousel: [
                {
                    id: 1,
                    name:"mastercall",
                    srcimg: 'images/client/mastercall-logo.webp',
                },
                {
                    id: 2,
                    name:"shopify",
                    srcimg: 'images/client/logo-slide-02.webp',
                },
                {
                    id: 3,
                    name:"fontimes",
                    srcimg: 'images/client/fonttime-logo.webp',
                },
                {
                    id: 4,
                    name:"win4win",
                    srcimg: 'images/client/win4win-logo.webp',
                },
                {
                    id: 5,
                    name:"inkassolution",
                    srcimg: 'images/client/Inkassolution-logo.webp',
                },
                {
                    id: 6,
                    name:"inkassoregister",
                    srcimg: 'images/client/inkasso-register-logo.webp',
                },
                {
                    id: 7,
                    name:"vgis",
                    srcimg: 'images/client/vgis-logo.webp',
                },
                {
                    id: 8,
                    name:"telprotector",
                    srcimg: 'images/client/telprotector-logo.webp',
                },
                {
                    id: 9,
                    name:"refonlution",
                    srcimg: 'images/client/refonlution-logo.webp',
                },
                {
                    id: 10,
                    name:"xcitelogo",
                    srcimg: 'images/client/xcite-logo.webp',
                },
                {
                    id: 11,
                    name:"sofort-handelsregiter",
                    srcimg: 'images/client/sofort-handelsregister-logo.webp',
                }
            ], 
        }
    }
     // show notices
     showCarousel = (e) => {
        var x = document.getElementById(e);
        var displaySetting = x.style.visibility;
        if (displaySetting === "hidden") {
            x.style.visibility = "visible";

        } else {
            x.style.visibility = "hidden";
        }
    }
   
    render() {
        if(this.props.clientdata.Section3 )
        this.showCarousel("client")
        return (
            <>
            <section className="flat-row flat-client bg-theme " style={{visibility:'hidden'}} id="client">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12"> 
									
									
                            <ul className="flat-carousel" data-item="5" data-nav="false" data-dots="false" data-auto="true">
                                
                                    {this.state.imgCarousel.map((data) =>(
                                        <li className="item " key={data.id} >
                                            <img src={data.srcimg} alt={data.name} width={170} height={50} />
                                        </li>
                                    ))
                                }
                                
                            </ul>
                        </div> 
                        {/* <!-- /.col-md-12 --> */}
                    </div> 
                    {/* <!-- /.row --> */}
                    
                </div> 
                {/* <!-- /.container --> */}
            </section> 
            {this.props.clientdata.Section3?
            <Footer/>
            :null}
            </>
        );
    }
}

export default CarouselClient;